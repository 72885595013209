@import "variables";

.box {
  display: flex;
  padding: unt(2);
  border-radius: unt(2);
  box-shadow: $shadow-light;
  border: 1px solid var(--color-stroke);
  background-color: var(--color-background);
  align-items: baseline;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  transition: $transition;


  .circle {
    width: unt(1);
    height: unt(1);
    min-width: unt(1);
    min-height: unt(1);
    border-radius: 50%;
    margin-right: unt(1);
    background-color: var(--color-guideline-cold);

    &.cold {
      background-color: var(--color-guideline-cold);
    }

    &.skin {
      background-color: var(--color-guideline-skin);
    }

    &.moving {
      background-color: var(--color-guideline-moving);
    }

    &.diabetes {
      background-color: var(--color-guideline-diabetes);
    }

    &.vaccinate {
      background-color: var(--color-guideline-vaccinate);
    }

    &.cancer {
      background-color: var(--color-guideline-cancer);
    }

    &.pregnancy {
      background-color: var(--color-guideline-pregnancy);
    }
  }
}
