@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  src: url(src/assets/fonts/DMSerifDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Simplon Norm';
  font-style: normal;
  font-weight: 400;
  src: url(src/assets/fonts/SimplonNorm-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Simplon Norm';
  font-style: normal;
  font-weight: 700;
  src: url(src/assets/fonts/SimplonNorm-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Simplon Norm';
  font-style: normal;
  font-weight: 500;
  src: url(src/assets/fonts/SimplonNorm-Medium.otf) format('opentype');
}

h1 {
  font-family: "Simplon Norm";
  font-size: pxToRem(20);
  line-height: 135%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: var(--color-secondary);
}

h1.schmuck {
  font-family: "DM Serif Display";
  font-size: pxToRem(24);
  line-height: 125%;
  letter-spacing: 0.03em;
  font-weight: 400;
}

h2 {
  font-family: "Simplon Norm";
  font-size: pxToRem(18);
  line-height: 135%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: var(--color-secondary);
}

h2.schmuck {
  font-family: "DM Serif Display";
  font-size: pxToRem(20);
  line-height: 100%;
  letter-spacing: 0.03em;
  font-weight: 400;
}

h3 {
  font-family: "Simplon Norm";
  font-size: pxToRem(16);
  line-height: 135%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: var(--color-secondary);
}

h3.schmuck {
  font-family: "DM Serif Display";
  font-size: pxToRem(18);
  line-height: 100%;
  letter-spacing: 0.03em;
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.center {
  text-align: center;
}

body, p, .body {
  font-family: "Simplon Norm";
  font-weight: 400;
  font-size: pxToRem(16);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-font);
}

.body-small{
  font-family: "Simplon Norm";
  font-weight: 400;
  font-size: pxToRem(14);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-font);
}

.header-title {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-secondary);
}

.card-title {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: 135%;
  letter-spacing: 0.02em;
  color: var(--color-font);
}

.paragraph-highlight {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: 175%;
  letter-spacing: 0.02em;
  color: var(--color-secondary);

  *, p {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
  }
}

.paragraph {
  font-family: "Simplon Norm";
  font-weight: 400;
  font-size: pxToRem(16);
  line-height: 175%;
  letter-spacing: 0.02em;
  color: var(--color-font);
}

.caption-flag {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(12);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-main);
  text-transform: uppercase;
}

.caption {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(12);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-shade-dark);
}

.label {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(14);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-font);
}

.label-form {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(14);
  line-height: 120%;
  letter-spacing: 0.02em;
  color: var(--color-secondary);

  &.label-form--font {
    color: var(--color-font);
  }
}


.label-form-helper {
  font-family: "Simplon Norm";
  font-weight: 400;
  font-size: pxToRem(12);
  line-height: 130%;
  letter-spacing: 0.02em;
  color: var(--color-shade-dark);
}

.filter {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(16);
  line-height: 150%;
  letter-spacing: 0.05em;
  color: var(--color-font);
}

.button-text {
  font-family: "Simplon Norm";
  font-weight: 500;
  font-size: pxToRem(14);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-main);
  text-transform: uppercase;
  cursor: pointer;
}

a {
  &.button-text {
    text-transform: unset;
    text-decoration: none;
    font-size: pxToRem(16);
  }
}

.link-text {
  font-family: "Simplon Norm";
  font-weight: 400;
  font-size: pxToRem(16);
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
}

.no-hyphen {
  hyphenate-character: '';
  -webkit-hyphenate-character: '';
}

.center {
  text-align: center;
}

.color--secondary {
  color: var(--color-secondary);
}
