.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col-xsmall,
.col-xsmall-1,
.col-xsmall-2,
.col-xsmall-3,
.col-xsmall-4,
.col-xsmall-5,
.col-xsmall-6,
.col-xsmall-7,
.col-xsmall-8,
.col-xsmall-9,
.col-xsmall-10,
.col-xsmall-11,
.col-xsmall-12,
.col-xsmall-offset-0,
.col-xsmall-offset-1,
.col-xsmall-offset-2,
.col-xsmall-offset-3,
.col-xsmall-offset-4,
.col-xsmall-offset-5,
.col-xsmall-offset-6,
.col-xsmall-offset-7,
.col-xsmall-offset-8,
.col-xsmall-offset-9,
.col-xsmall-offset-10,
.col-xsmall-offset-11,
.col-xsmall-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xsmall {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.col-xsmall-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xsmall-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xsmall-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xsmall-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xsmall-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xsmall-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xsmall-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xsmall-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xsmall-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xsmall-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xsmall-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xsmall-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xsmall-offset-0 {
  margin-left: 0;
}

.col-xsmall-offset-1 {
  margin-left: 8.33333333%;
}

.col-xsmall-offset-2 {
  margin-left: 16.66666667%;
}

.col-xsmall-offset-3 {
  margin-left: 25%;
}

.col-xsmall-offset-4 {
  margin-left: 33.33333333%;
}

.col-xsmall-offset-5 {
  margin-left: 41.66666667%;
}

.col-xsmall-offset-6 {
  margin-left: 50%;
}

.col-xsmall-offset-7 {
  margin-left: 58.33333333%;
}

.col-xsmall-offset-8 {
  margin-left: 66.66666667%;
}

.col-xsmall-offset-9 {
  margin-left: 75%;
}

.col-xsmall-offset-10 {
  margin-left: 83.33333333%;
}

.col-xsmall-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media only screen and (min-width: $breakpoint-small) {
  .container {
    width: 49rem;
  }

  .col-smallm,
  .col-smallm-1,
  .col-smallm-2,
  .col-smallm-3,
  .col-smallm-4,
  .col-smallm-5,
  .col-smallm-6,
  .col-smallm-7,
  .col-smallm-8,
  .col-smallm-9,
  .col-smallm-10,
  .col-smallm-11,
  .col-smallm-12,
  .col-smallm-offset-0,
  .col-smallm-offset-1,
  .col-smallm-offset-2,
  .col-smallm-offset-3,
  .col-smallm-offset-4,
  .col-smallm-offset-5,
  .col-smallm-offset-6,
  .col-smallm-offset-7,
  .col-smallm-offset-8,
  .col-smallm-offset-9,
  .col-smallm-offset-10,
  .col-smallm-offset-11,
  .col-smallm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-smallm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-smallm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-smallm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-smallm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-smallm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-smallm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-smallm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-smallm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-smallm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-smallm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-smallm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-smallm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-smallm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-smallm-offset-0 {
    margin-left: 0;
  }

  .col-smallm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-smallm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-smallm-offset-3 {
    margin-left: 25%;
  }

  .col-smallm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-smallm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-smallm-offset-6 {
    margin-left: 50%;
  }

  .col-smallm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-smallm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-smallm-offset-9 {
    margin-left: 75%;
  }

  .col-smallm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-smallm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .container {
    width: 65rem;
  }

  .col-medium,
  .col-medium-1,
  .col-medium-2,
  .col-medium-3,
  .col-medium-4,
  .col-medium-5,
  .col-medium-6,
  .col-medium-7,
  .col-medium-8,
  .col-medium-9,
  .col-medium-10,
  .col-medium-11,
  .col-medium-12,
  .col-medium-offset-0,
  .col-medium-offset-1,
  .col-medium-offset-2,
  .col-medium-offset-3,
  .col-medium-offset-4,
  .col-medium-offset-5,
  .col-medium-offset-6,
  .col-medium-offset-7,
  .col-medium-offset-8,
  .col-medium-offset-9,
  .col-medium-offset-10,
  .col-medium-offset-11,
  .col-medium-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-medium {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-medium-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-medium-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-medium-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-medium-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-medium-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-medium-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-medium-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-medium-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-medium-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-medium-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-medium-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-medium-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-medium-offset-0 {
    margin-left: 0;
  }

  .col-medium-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-medium-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-medium-offset-3 {
    margin-left: 25%;
  }

  .col-medium-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-medium-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-medium-offset-6 {
    margin-left: 50%;
  }

  .col-medium-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-medium-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-medium-offset-9 {
    margin-left: 75%;
  }

  .col-medium-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-medium-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .container {
    width: 76rem;
  }

  .col-large,
  .col-large-1,
  .col-large-2,
  .col-large-3,
  .col-large-4,
  .col-large-5,
  .col-large-6,
  .col-large-7,
  .col-large-8,
  .col-large-9,
  .col-large-10,
  .col-large-11,
  .col-large-12,
  .col-large-offset-0,
  .col-large-offset-1,
  .col-large-offset-2,
  .col-large-offset-3,
  .col-large-offset-4,
  .col-large-offset-5,
  .col-large-offset-6,
  .col-large-offset-7,
  .col-large-offset-8,
  .col-large-offset-9,
  .col-large-offset-10,
  .col-large-offset-11,
  .col-large-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-large {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-large-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-large-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-large-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-large-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-large-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-large-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-large-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-large-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-large-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-large-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-large-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-large-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-large-offset-0 {
    margin-left: 0;
  }

  .col-large-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-large-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-large-offset-3 {
    margin-left: 25%;
  }

  .col-large-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-large-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-large-offset-6 {
    margin-left: 50%;
  }

  .col-large-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-large-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-large-offset-9 {
    margin-left: 75%;
  }

  .col-large-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-large-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
