.Paperform__popupwrapper--active {

  .Paperform__popupclose {
    top: 25px;
    right: 25px;

    @media all and (max-width: 1024px) {
      top: 100px !important;
    }
  }


  .Paperform__popupcontent {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 16px;

    @media all and (max-width: 1024px) {
      padding: 90px 16px;
    }

    iframe {
      box-shadow: $shadow-default;
      border-radius: 16px;
      min-height: calc(100vh - 180px) !important;
    }
  }
}


@media screen and (max-width: 10000px) {
  html > body.Paperform--locked > app-root {
    display: block !important;

  }
}
