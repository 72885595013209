:root {
 --color-main: #E3000F;
 --color-secondary: #19326E;
 --color-font: #202124;
 --color-white: #FFFFFF;
 --color-background: #F9F9FA;
 --color-stroke: #DADADA;
 --color-shade-light: #A2A2A2;
 --color-shade-dark: #666666;

 --color-guideline-cold: #0086C6;
 --color-guideline-skin: #FFC234;
 --color-guideline-moving: #FC634C;
 --color-guideline-diabetes: #70BF63;
 --color-guideline-vaccinate: #9A63A1;
 --color-guideline-cancer: #B67A3F;
 --color-guideline-pregnancy: #FA659F;

 --color-notification-bg: #F9F9FA;
 --color-alert-bg: #FBF3EE;

 --base-font-size: 16px;
 --base-font-size-no-unit: 16;
 --font-size-small: 14px;

 --safe-area-top: 35px;

}
