@import "variables";

html {
  overflow-x: hidden;
}

body {
  background-color: var(--color-background);
  overflow-x: hidden;
}

.spacer {
  display: block;
  width: 100%;
  height: unt(1);
}

.spacer-half {
  display: block;
  width: 100%;
  height: unt(0.5);
}

.spacer-and-half {
  display: block;
  width: 100%;
  height: unt(1.5);
}

@for $i from 1 through 20 {
  .spacer-#{$i} {
    display: block;
    width: 100%;
    height: unt(#{$i})
  }
}

.container {
  max-width: 600px;
  position: relative;

  box-shadow: 0 -16px 30px rgba(0, 0, 0, 0.06);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  height: calc(100vh - #{unt(7)} + var(--safe-area-top));
  top: calc(#{unt(7)} + var(--safe-area-top));
}

.content {
  // height: 100%;
  width: 100%;
  padding: unt(2);
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--color-stroke);
}

.iframe__wrapper {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}
