


//## Transitions
$transition-duration: 300ms;
$transition-easing: cubic-bezier(0.4, 0, 0.2, 1);

$transition-duration-short: 150ms;
$transition-duration-long: 450ms;

//default
$transition: all $transition-duration $transition-easing;


//## Breakpoints
$breakpoint-small: 768px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1440px;
$breakpoint-container-width: 600px;


//## Unit
@function unt($num) {
  @return calc(8 / #{var(--base-font-size-no-unit)} * #{$num}rem);
}

//## pxToRem
@function pxToRem($num){
  @return calc(#{$num} / #{var(--base-font-size-no-unit)} * 1rem);
}


//## Shadow
$shadow-none: rgba(black, 0) 0px 0px 0px;
$shadow-default: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
$shadow-hover: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
$shadow-light: 0 16px 32px rgba(0, 0, 0, 0.06);
$shadow-light-top: 0 -16px 32px rgba(0, 0, 0, 0.16);
$shadow-normal: 0 16px 32px rgba(0, 0, 0, 0.12);
$shadow-strong: 0 16px 32px rgba(0, 0, 0, 0.16);
$shadow-button: 0 0 0 unt(0.5) var(--color-stroke);

$default-border: 1px solid rgba(218, 218, 218, 1);
$callout-background: linear-gradient(0deg, #F9F9FA, #F9F9FA),
linear-gradient(0deg, #DADADA, #DADADA);




@mixin modalBackground() {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-font);
  opacity: 0;
  top: 0;
  pointer-events: none;
  transition: opacity 250ms;
}

@mixin modalBackgroundOpen() {
  display: block;
  opacity: 0.2;
  pointer-events: unset;
  cursor: pointer;
}
